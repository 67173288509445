.card {
  display: block;
  border: 1px solid lightgray;
  border-radius: 4px;
  padding: 1.25em;
  background: #fff;
  min-height: 15vh;
}

.card:hover {
  transition: all .25s;
  border: 1px solid var(--light-blue);
  cursor: pointer;
}

.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-name {  
  font-size: 15px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: #000;
}

.card-body {
  margin-top: .75em;
  display: flex;
}

.card-body .left, .card-body .right {
  flex: 10vw;
}

.card-grid {
  margin-top: 1em;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.card .chart-container {
  margin-left: 2em;
  max-width: 8vw;
  max-height: 12vh;
} 

.chart-header {
  width: 100%;
  height: 3.5vh;
  background: #f3f5f3;
  border-radius: 7px;
  border: 1px solid rgb(211, 211, 211);
}

.figure {
  font-size: 25px;
  font-weight: 500;
  color: #000;
}

.type {
  font-size: 1.4rem;
  font-weight: 400;
  color: #000;
}

.unit {
  color: rgba(0, 0, 0, .8);
  font-size: .885rem;
  font-weight: 400;
}

.snapshot-container {
  position: relative;
  margin-left: .75em;
  width: 7vw;
  height: 8.6vh;
}

.negative {
  color: red;
}

@media (max-width: 900px) {
  .card-name {
    font-size: 1.2rem;
    font-weight: 600;
  }

  .card-content {
    margin-top: .8em;
  }

  .quantity {
    font-size: 1.75rem;
  }

  .snapshot-container {
    margin-left: 0;
    width: 40vw;
    height: 10vh;
  }
}