.nav-bar {
  top: 0;
  left: 0;
  flex: 0 0 285px;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1.5em 2em;
  background: #2b2b2b;
  animation: slide-in .5s 1;
  z-index: 1;
}

.namespace {
  margin: 2em 0 1em 0;
  color: rgba(255, 255, 255, .7);
  font-weight: 500;
  font-size: .775rem;
  letter-spacing: .5px;
  text-transform: uppercase;
}

.menu {
  position: relative;
  list-style-type: none;
}

.menu li {
  margin: 2px 0;
  position: relative;
  padding: .75em;
  border-radius: 5px;
}

.menu li:hover, .selected {
  background-color: #fff;
}

.menu li:hover span {
  color: #19175A;
}

.menu a {
  display: flex;  
  align-items: center;
}

.icon {
  margin-right: .75em;
  max-width: 1.4em;
  max-height: 1.3em;
}

path {
  stroke-width: 10px;
}

.menu a span {
  margin-left: 1.25em;
  font-size: .950rem;
  font-weight: 400;
  color: #fff;
}

.close {
  display: none;
}

.collapsed {
  display: none;
}

.hidden {
  visibility: hidden;
}

.selected span {
  color: #a199e8;
}

@media (max-width: 900px) {
  .nav-bar {
    position: fixed;
    top: 0;
    left: 0;
    padding: 1em;
    width: 60vw;
    z-index: 1;
  }

  .close {
    display: block;
    width: 1.5em;
  }
}

@keyframes slide-in {
  0% {margin-left: -100px;}
  100% {margin-right: 0;}
}