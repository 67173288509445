.info {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 10, .5);
    color: #fff;
    z-index: 1;
}

.info .logo {
    margin-top: 0;
}

.info .container {
    position: absolute;
    width: 30vw;
    height: fit-content;
    padding: 3em;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    background: var(--blue);
    animation: fadeIn .4s 1;
}

.info p {
    margin-top: 2em;
    text-align: right;
    line-height: 1.75rem;
    font-size: .9rem;
    text-align: justify;   
}

.info p:last-child {
    margin-top: 3em;
    background: red;
}

.info .icon-close {
    position: absolute;
    right: 8%;
    width: .9em;
    cursor: pointer;
}

.info a {
    color: #fff;
    font-weight: bold;
}

@media (max-width: 900px) {
    .info .container {
        width: 80vw;
        padding: 1.5em;
    }
}