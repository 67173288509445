.main {
  position: relative;
  flex: 1;
}

.content {
  margin: 0 auto;
  position: relative;
  width: 55%;
  padding: 3em 0;
}

.overview h1 {
  margin-top: 1.5em;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: 500;
  opacity: .6;
}

.content > div > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}