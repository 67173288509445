@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600&display=swap');

:root {
  --blue: #0038a8; 
  --yellow: #fcd116;
  --white: #fff;
  --light-blue: #3B96D8;
  --background-color: #f5f5f9;
  --hover: #290869;
  --border-radius: 6px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body, .App, #root {
  position: relative;
  height: 100%
}

a {
  text-decoration: none;
}

.App {
  display: flex;
}

body {
  background-color: var(--background-color);
  font-family: "Open Sans", sans-serif;
}

a {
  text-decoration: none;
}

.indicators-page, .details-page {
  position: relative;
  padding: 2em;
}

.details-page {
  display: flex;
}

.indicators-page header {
  display: flex;
}

.counter {
  font-size: .8rem;
  font-weight: 500;
  padding: 3px 7px;
  background: #f1095a;
  color: #fff;
  border-radius: .5em;
}

.indicators-grid {
  margin-top: 1.5em;
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  height: 100%;
}

.fill {
  margin-right: 5px;
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.indicator-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.updated {
  display: none;
  align-items: center;
  font-size: 13px;
  opacity: .9;
}

.updated img {
  margin-right: 5px;
}

.indicator-area {
  margin-right: 2em;
}

.icon-clock {
  margin-top: 5px;
  margin-right: .5em;
  height: .5em;
}

.indicator-info {
  margin-top: 1.25em;
  font-size: 1rem;
  background: #fff;
  padding: 1.5em;
  border-radius: 5px;
  box-shadow: 0 0 3px rgba(0, 0, 0, .15);
}

.indicator-details {
  display: flex;
  flex-direction: column;
}

.indicator-details h1 {
  margin-top: .25em;
  font-size: 22px;
  font-weight: 400;
}

.indicator-details > div {
  display: flex;
}

.indicator-details .left {
  flex: 70%;
}

.indicator-details .chart-container {
  margin-top: 2em;
  background: #fff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.75em;
  width: 100%;
  height: 55vh;
  box-shadow: 0 0 3px rgba(0, 0, 0, .15);
}

.indicator-details .right {
  margin-left: 1.25em;
  flex: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1.25em;
}

.indicator-details .right * {
  flex: 1;
}

.indicator-news {
  background: #fff;
  border-radius: 5px;
  padding: 1.5em;
  box-shadow: 0 0 3px rgba(0, 0, 0, .15);
}

.indicator-description {
  margin-top: 1.25em;
  line-height: 1.75em;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 15px;
  text-align: justify;
  min-height: 5em;
  color: rgba(0, 0, 0, .7);
}

.indicator-info h5, .indicator-news h5 {
  font-size: 16px;
  font-family: 'Roboto' sans-serif;
  font-weight: 500;
}

.indicator-info p {
  margin-top: 1.25em;
  font-family: 'Roboto', sans-serif;
  opacity: .8;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.75em;
}

.path {
  font-family: 'Roboto Condensed';
  font-size: 14px;
  font-weight: 400;
  opacity: .6;
}

.container {
  margin: 0 auto;
  position: relative;
  width: 70%;
  padding: 2.5em 0;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@media (max-width: 900px) {
  .indicators-grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .indicators-page, .details-page {
    padding: 1em;
  }

  .details-page {
    flex-direction: column;
  }

  .chart-area {
    margin-top: 1.5em;
  }

  .indicator-area {
    margin-right: 0;
  }

  .more-indicators {
    margin-top: 2em;
    margin-left: 0;
  }

  .chart-area .container {
    padding: 1em;
  }

  .chart-header .left {
    display: none;
  }

  .card .chart-container {
    height: 30vh;
  }

  h1 {
    margin-top: .5em;
    font-size: 1.3rem;
  }

  .indicators-grid {
    grid-gap: 1em;
  }

  .indicator-info {
    margin-top: .5em;
  }

  .indicator-description {
    line-height: 1.5em;
  }
}