.indicators-by-category .grid {
    margin-top: 2em;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 8px;
}

.indicators-by-category {
    width: 100%;
}

.indicators-by-category h1 {
    margin-top: 5px;
    font-size: 26px;
    font-weight: 600;
}