
.source {
    margin-left: auto;
    margin-top: 2.5em;
    font-size: 13px;
    color: #000;
    opacity: .5;
}

.source:hover {
    text-decoration: underline;
}

.chart-body {
    margin-top: 1.25em;
    display: flex;
    flex-direction: column;
}