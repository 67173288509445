.suggestions {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: var(--white);
    border-radius: 0 0 10px 10px;
    box-shadow: 0px 1px 3px lightgray;
    z-index: 1;
    cursor: pointer;
}

.suggestions a {
    display: block;
    padding: 1em 1.5em;
    font-size: .9rem;
    font-weight: 5  00;
    color: #000;
    cursor: pointer;
}

.suggestions a:hover {
    border-left: 2px solid gold;
    background: var(--background-color);
    color: var(--blue);
}

.open {
    display: block;
}

.closed {
    display: none;
}