header {
  width: 100%;
  padding: .9em 0;
  background: var(--white);
  box-shadow: 0 0 5px rgba(0, 0, 0, .15);
  display: flex;
  align-items: center;
  z-index: 1;
}

.logo-container {
  flex: 1;
}

.burger {
  display: none;
  cursor: pointer;
  border-radius: var(--border-radius);
}

header > div {
  margin: 0 auto;
  width: 70%;
  display: flex;
  justify-content: space-between;
}

.donate {
  flex: 1;
  display: flex;
  align-items: center;
}

.donate-button {
  margin-left: auto;
  padding: 12px 36px;
  border-radius: 8px;
  background-color: gray;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
}

.donate-button:hover {
  /* background-color: #238f3c; */
}

.burger div {
  background: rgba(0, 0, 0, .7);
  border-radius: 1px;
  width: .85vw;
  height: 2px;
}

.burger div:nth-child(2) {
  margin: 3px 0;
}

.searchbox-container {
  position: relative;
  display: flex;
  padding-left: 1.25em;
  flex: 2;
  align-items: center;
  background: #f4f4f4;
  border-radius: 6px;
}

.searchbox-container div:first-child {
  display: flex;
  align-items: center;
}

.searchbox-container input {
  margin-left: 5px;
  padding: .8em 1.25em .8em .75em;
  width: 22vw;
  background: none;
  font-size: 1rem;
  border: none;
  outline: none;
  font-family: "Open Sans", sans-serif;
}

.border {
  background: red;
  border-radius: 2em 2em 0 0;
}

.searchbox-container:focus-within {
  transition: all .2s;
  background: #ebebeb;
}

.searchbox-container input::placeholder {
  font-size: .850rem;
  font-weight: 400;
}

.search-icon {
  position: relative;
  width: 18px;
}

.searchbox-container .button {
  position: absolute;
  top: 0;
  right: 0;
}

.button img {
  margin-right: 7px;
  width: 1.2em;
}

.language-switcher {
  display: none;
  justify-content: space-between;
  align-items: center;
  background-color: #bff3d8;
  border-radius: 1em;
}

.language-switcher a {
  font-size: .8rem;
  font-weight: 500;
  padding: .3em .8em;
  color: #000;
}

.language-switcher .active {
  background: var(--blue);
  color: var(--white);
  border-radius: 2em;
}

.button {
  position: relative;
  padding: 10px 16px;
  display: flex;
  align-items: center;
  background: #0d73e7;
  font-size: .9rem;
  font-weight: 500;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}

/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

/* clears the ‘X’ from Internet Explorer */
input[type=search]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type=search]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

@media (max-width: 900px) {
  header {
    padding: 1em 1em;
  }
  
  .searchbox-container {
    margin-left: 0;
  }

  header > div:first-child {
    flex: auto;
  }

  header > div:last-child {
    flex: auto;
  }

  .button img {
    margin-right: 0;
  }

  .button span {
    display: none;
  }

  .burger div {
    width: 1em;
  }

  .searchbox-container input {
    margin-right: .5em;
    width: 50vw;
  }

  .search-icon {
    display: none;
  }
}